define([], function() {

  //// See https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Safely_detecting_option_support
  let supportsCaptureOption = false;
  function setHasSupportToCaptureOption(hasSupport) {
    supportsCaptureOption = hasSupport;
  }

  try {
    addEventListener('test', null, Object.defineProperty({}, 'capture', {get: function () {
      setHasSupportToCaptureOption(true);
    }}));
  } catch(e) {/**/}

  function getSafeEventHandlerOpts(options = { capture: true }) {
    return supportsCaptureOption ? options : options.capture;
  }
  ////

  function noop() {}

  function getPosition(event) {
    if ('touches' in event) {
      const { pageX, pageY } = event.touches[0];
      return { x: pageX, y: pageY };
    }

    const { screenX, screenY } = event;
    return { x: screenX, y: screenY };
  }

  class SwipeListener {

    constructor(props) {
      this._handleSwipeStart = this._handleSwipeStart.bind(this);
      this._handleSwipeMove = this._handleSwipeMove.bind(this);
      this._handleSwipeEnd = this._handleSwipeEnd.bind(this);

      this._onMouseDown = this._onMouseDown.bind(this);
      this._onMouseMove = this._onMouseMove.bind(this);
      this._onMouseUp = this._onMouseUp.bind(this);

      this.swiper = props.target;

      if (this.swiper) {
        this.swiper.addEventListener('touchmove', this._handleSwipeMove, getSafeEventHandlerOpts({
          capture: true,
          passive: false
        }));
        this.swiper.addEventListener('mousedown', this._onMouseDown, {passive: true});
        this.swiper.addEventListener('touchstart', this._handleSwipeStart, {passive: true});
        this.swiper.addEventListener('touchend', this._handleSwipeEnd, {passive: true});
      }
      this.props = props;
      props.onSwipeStart = props.onSwipeStart || noop;
      props.onSwipeMove = props.onSwipeMove || noop;
      props.onSwipeEnd = props.onSwipeEnd || noop;
      props.onSwipeLeft = props.onSwipeLeft || noop;
      props.onSwipeRight = props.onSwipeRight || noop;
      props.onSwipeUp = props.onSwipeUp || noop;
      props.onSwipeDown = props.onSwipeDown || noop;
    }


    _onMouseDown(event) {
      if (!this.props.allowMouseEvents) {
        return;
      }
      this.mouseDown = true;

      document.addEventListener('mouseup', this._onMouseUp, {passive: true});
      document.addEventListener('mousemove', this._onMouseMove, {passive: true});
      event.preventDefault();
      this._handleSwipeStart(event);
    }

    _onMouseMove(event) {
      if (!this.mouseDown) {
        return;
      }

      this._handleSwipeMove(event);
    }

    _onMouseUp(event) {
      this.mouseDown = false;

      document.removeEventListener('mouseup', this._onMouseUp);
      document.removeEventListener('mousemove', this._onMouseMove);

      this._handleSwipeEnd(event);
    }

    _handleSwipeStart(event) {
      const { x, y } = getPosition(event);
      this.moveStart = { x, y };
      this.props.onSwipeStart(event, this.moveStart);
    }

    _handleSwipeMove(event) {
      if (!this.moveStart) {
        return;
      }
      const { x, y } = getPosition(event);
      const deltaX = x - this.moveStart.x;
      const deltaY = y - this.moveStart.y;
      this.moving = true;

      // hand the responsibility of cancelling the scroll to
      // the component handling the event
      const shouldPreventDefault = this.props.onSwipeMove({
        x: deltaX,
        y: deltaY
      }, event);

      if (shouldPreventDefault) {
        event.preventDefault();
      }

      this.movePosition = { deltaX, deltaY };
    }

    _handleSwipeEnd(event) {
      this.props.onSwipeEnd(event);

      const { tolerance } = this.props;

      if (this.moving && this.movePosition) {
        if (this.movePosition.deltaX < -tolerance) {
          this.props.onSwipeLeft(event);
        } else if (this.movePosition.deltaX > tolerance) {
          this.props.onSwipeRight(event);
        }
        if (this.movePosition.deltaY < -tolerance) {
          this.props.onSwipeUp(event);
        } else if (this.movePosition.deltaY > tolerance) {
          this.props.onSwipeDown(event);
        }
      }

      this.moveStart = null;
      this.moving = false;
      this.movePosition = null;
    }

  }

  return SwipeListener;
});
